/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { GetPrequalifiedCTA } from './GetPrequalifiedCTA';
import { ContactLeadForm } from './ContactLeadForm';
import { StickyButtons } from './StickyButtons';
import { ModalContactLead } from './ModalContactLead';
import './styles.css';

export const FinanceableBoatSummaryMobile = (props) => {

  const {
    isUserLoginExperiment,
    listing,
    breakpoint,
    userEmail,
    setDisableRequestInfo,
    disableRequestInfo,
    isHiddenButtons,

    showLeadOverlay,
    showDetailsContact,
    showDetailsSuccess,
    setShowDetailsSuccess,
    setShowDetailsContact,
    showFinanceCTAs,
    setShowLeadOverlay,
    handleDetailsSuccess,
    handleLoginContactButtonClick,
    handleCloseContactForm
  } = props;

  const userEmailAvailable = userEmail !== undefined;

  return (
    <>
      <div id="prequalified-and-contact-seller" className="info-container">
        <ContactLeadForm
          listing={listing}
          isMobile={true}
          breakpoint={breakpoint}
          userEmailAvailable={userEmailAvailable}
          isUserLoginExperiment={isUserLoginExperiment}
          disableRequestInfo={disableRequestInfo}
          setDisableRequestInfo={setDisableRequestInfo}
          showDetailsSuccess={showDetailsSuccess}
          handleDetailsSuccess={handleDetailsSuccess}
          handleLoginContactButtonClick={handleLoginContactButtonClick}
        />
        {showFinanceCTAs &&
          <GetPrequalifiedCTA listing={listing}/>
        }
      </div>

      <StickyButtons
        listing={listing}
        showFinanceCTAs={showFinanceCTAs}
        userEmailAvailable={userEmailAvailable}
        isUserLoginExperiment={isUserLoginExperiment}
        userEmail={userEmail}
        isHiddenButtons={isHiddenButtons}
        showDetailsContact={showDetailsContact}
        setShowLeadOverlay={setShowLeadOverlay}
        setShowDetailsContact={setShowDetailsContact}
        setShowDetailsSuccess={setShowDetailsSuccess}
        handleLoginContactButtonClick={handleLoginContactButtonClick}/>

      <ModalContactLead
        listing={listing}
        isMobile={true}
        isUserLoginExperiment={isUserLoginExperiment}
        userEmail={userEmail}
        breakpoint={breakpoint}
        showLeadOverlay={showLeadOverlay}
        showDetailsContact={showDetailsContact}
        setDisableRequestInfo={setDisableRequestInfo}
        handleDetailsSuccess={handleDetailsSuccess}
        handleCloseContactForm={handleCloseContactForm} />

    </>);};
