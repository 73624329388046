/* eslint-disable react-hooks/exhaustive-deps */
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { withABTest } from '../../../server/abTest/withABTest';
import { buyerAccountLoginExperiment } from '../../../utils/fsbo/experiments';
import * as buyerAccountLoginUtils from '../../../utils/fsbo/buyerAccountLogin';
import { getSessionItem, removeSessionItem, setSessionItem } from '../../../utils/storage';
import { isFinanceable, makeFinanceableSummaryTrackingEvent } from '../../../utils/trident';
import { FinanceableBoatSummaryMobile } from './ui.mobile';
import { FinanceableBoatSummaryDesktop } from './ui.desktop';
import './styles.css';

const FinanceableBoatSummary = (props) => {

  const {
    abTestConfiguration,
    listing,
    isMobile,
    openModalPostLead,
    userRef,
    userEmail,
  } = props;

  const [isHiddenButtons, setIsHiddenbuttons] = useState(false);
  const [contactSellerClicked, setContactSellerClicked] = useState(false);
  const [showDetailsContact, setShowDetailsContact] = useState(false);
  const [showLeadOverlay, setShowLeadOverlay] = useState(false);
  const [showDetailsSuccess, setShowDetailsSuccess] = useState(false);

  const isUserLoginExperiment = buyerAccountLoginExperiment.isActive(abTestConfiguration, listing);
  const price = get(listing, 'price.type.amount.USD');
  const showFinanceCTAs = isFinanceable(price, listing?.year);
  const userEmailAvailable = userEmail !== undefined;

  useEffect(() => {
    const displayContactSeller = getSessionItem(buyerAccountLoginUtils.getSessionKey());
    const showLeadForm = displayContactSeller || contactSellerClicked;

    if (userEmailAvailable && userEmail && showLeadForm) {
      setShowLeadOverlay(true);
      setShowDetailsContact(true);
      setContactSellerClicked(false);
      setIsHiddenbuttons(isMobile);
    }
  }, [userEmailAvailable, userEmail, contactSellerClicked]);

  useEffect(() => {
    // Disable the email field for the experiment because there is not a an easy
    // way to disable the email field in the BDPEmailLeadForm
    if (isUserLoginExperiment && userEmail) {
      document.getElementById('financeemail')?.setAttribute('readonly', true);
    }
  }, [isUserLoginExperiment, userEmail, showDetailsContact]);

  const handleLoginContactButtonClick = (event, actionLabel) => {
    event.preventDefault();
    const contactTracking = makeFinanceableSummaryTrackingEvent(actionLabel, 'click');
    window.dataLayer.push(contactTracking);

    if (!userEmail) {
      userRef?.current?.onOpenModal();
      setSessionItem(buyerAccountLoginUtils.getSessionKey(), true);
    } else {
      setShowLeadOverlay(true);
    }
    setContactSellerClicked(true);
  };

  // common function for Email lead and modal lead in mobile
  const handleCloseContactForm = () => {
    setShowDetailsContact(false);
    setShowLeadOverlay(false);
    removeSessionItem(buyerAccountLoginUtils.getSessionKey());
  };

  const handleDetailsSuccess = (ld) => {
    if (openModalPostLead){
      openModalPostLead(ld);
    }
    const contactSubmitTracking = makeFinanceableSummaryTrackingEvent('contact form submit', 'contact form submit');
    window.dataLayer.push(contactSubmitTracking);
    setShowDetailsSuccess(true);
    handleCloseContactForm();
  };


  const summaryProps = {
    ...props,
    isUserLoginExperiment,
    isHiddenButtons,
    showLeadOverlay,
    showDetailsSuccess,
    showDetailsContact,
    showFinanceCTAs,

    setShowDetailsContact,
    setShowDetailsSuccess,
    setShowLeadOverlay,
    handleDetailsSuccess,
    handleLoginContactButtonClick,
    handleCloseContactForm
  };
  return (
    <div className="financeable-boat-summary-container" id="financeable-boat-summary-main-container">
      {isMobile ?
        <FinanceableBoatSummaryMobile {...summaryProps}/>
        :
        <FinanceableBoatSummaryDesktop {...summaryProps}/>
      }
    </div>);};

export default withABTest(FinanceableBoatSummary);
